import { FC } from 'react';
import { MenuBar } from './MenuBar';
import './DataView.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedDataMenuItem, selectedDateAtom } from '../../data-access';
import { DataViewMenuItems } from './constants';
import { DailyWeeklySwitcher } from './DailyWeeklySwitcher';
import { WeekDatesSwitcher } from './WeekDatesSwitcher';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const DataView: FC = () => {
  const selectedDataMenu = useRecoilValue(selectedDataMenuItem);
  const [_, setSelectedDate] = useRecoilState(selectedDateAtom);
  const DataViewComponent = DataViewMenuItems.find(
    (item) => item.id === selectedDataMenu
  ).component;

  const handleResetDate = () => {
    setSelectedDate(dayjs());
  };

  return (
    <div className="data-view">
      <MenuBar className="data-view__menu-bar" />
      <div className="data-view__dashboard">
        <div className="data-view__header">
          <button onClick={handleResetDate} className="data-view__header__title">
            <Typography variant="body1">Today</Typography>
          </button>
        </div>
        <div className="data-view__dashboard__content">
          <DailyWeeklySwitcher />
          <WeekDatesSwitcher />
          <DataViewComponent />
        </div>
      </div>
    </div>
  );
};

export default DataView;

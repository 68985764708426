import React, { FC } from 'react';
import { DataViewMenuItems } from '../constants';
import './MenuBar.scss';
import { useRecoilState } from 'recoil';
import { selectedDataMenuItem } from '../../../data-access';
import clsx from 'clsx';
import { Typography } from '@mui/material';

interface MenuBarProps {
  className?: string;
}

const MenuBar: FC<MenuBarProps> = ({ className }) => {
  const [selectedMenu, setSelectedDataMenuItem] = useRecoilState(selectedDataMenuItem);
  return (
    <div className={clsx('data-menu-bar', className)}>
      <div className="data-menu-bar__menu-container">
        {DataViewMenuItems.map((item) => (
          <div
            onClick={() => setSelectedDataMenuItem(item.id)}
            className={clsx(
              'data-menu-bar__menu',
              selectedMenu === item.id && 'data-menu-bar__menu--active'
            )}
          >
            <Typography variant="body1">{item.title}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuBar;

import React, { FC } from 'react';
import { DataTrendPeriod, dataTrendPeriodAtom } from '../../../data-access';
import { useRecoilState } from 'recoil';
import './DailyWeeklySwitcher.scss';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { Typography } from '@mui/material';

const DailyWeeklySwitcher: FC = () => {
  const [dataTrendPeriod, setDataTrendPeriod] = useRecoilState(dataTrendPeriodAtom);
  return (
    <div className="daily-weekly-switcher">
      {['daily', 'weekly'].map((period) => (
        <div
          className={clsx(
            'daily-weekly-switcher__title',
            period === dataTrendPeriod && 'daily-weekly-switcher__title--active'
          )}
          onClick={() => setDataTrendPeriod(period as DataTrendPeriod)}
        >
          <Typography variant="h6">{startCase(period)}</Typography>
        </div>
      ))}
    </div>
  );
};

export default DailyWeeklySwitcher;

import { FC } from 'react';
import { useEnergyTrend } from '../../../hooks';
import { EnergyGaugeCard } from './EnergyGaugeCard';
import './DataCircadianDashboard.scss';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { EnergyValuesCard } from './EnergyValuesCard';

interface DataCircadianDashboardProps {
  className?: string;
}

const DataCircadianDashboard: FC<DataCircadianDashboardProps> = ({ className }) => {
  const { dataTrendPeriod, energyDailyTrend, isLoading } = useEnergyTrend();

  return (
    <div className={clsx('circadian-dashboard', className)}>
      {isLoading ? (
        <div className="circadian-dashboard__loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="circadian-dashboard__energy">
          <EnergyGaugeCard
            peakEnergy={energyDailyTrend?.peakEnergy ?? 0}
            dataTrendPeriod={dataTrendPeriod}
            className="circadian-dashboard__box"
          />
          <EnergyValuesCard
            className="circadian-dashboard__box"
            energyDailyTrend={energyDailyTrend}
          />
        </div>
      )}
    </div>
  );
};

export default DataCircadianDashboard;
